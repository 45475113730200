import { render, staticRenderFns } from "./ManyProductsInfoSection.vue?vue&type=template&id=b42f9914&scoped=true"
import script from "./ManyProductsInfoSection.vue?vue&type=script&lang=js"
export * from "./ManyProductsInfoSection.vue?vue&type=script&lang=js"
import style0 from "./ManyProductsInfoSection.vue?vue&type=style&index=0&id=b42f9914&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b42f9914",
  null
  
)

export default component.exports
<template>
    <section class="inner-section" v-background="backgroundOverlay">
        <base-container>
			<div class="product-wrapper">
				<figure class="product-image-wrapper">
					<cms-image
						:src="product.thumbnail"
						:base="{height: 200}"
						:md="{height: 300}"
						:xxl="{height: 400}"
						class="product-image"
					/>
					<cms-image
						:src="product.brand"
						:base="{width: 100}"
						:lg="{width: 130}"
						class="product-brand"
					/>
				</figure>
				<div class="product-details">
					<div class="product-buttons">
						<base-category-feature v-for="item in product.tags.traits" :key="item" :name="item" type="product"/>
						<base-category-feature v-for="item in product.tags.categories" :key="item" :name="item" type="product"/>
					</div>
					<div class="text-content">
						<cms-text class="highlighted-text" :props="{tag:'h2', color:'gray', variant:'small-heading'}" :value="`${prefix}.highlighted_text`"/>
						<cms-text :props="{tag:'div'}" :value="`${prefix}.text_content`"/>
					</div>
				</div>
			</div>
        </base-container>
    </section>
</template>
<script>
import BaseCategoryFeature from '~/website/front/components/molecules/BaseCategory/BaseCategoryFeature.vue'
export default {
	components: { BaseCategoryFeature },
	props: {
		value: Object,
		prefix: String
	},
	computed: {
		backgroundOverlay () {
			const path = '/products/bg-overlay.jpg'
			return {
				src: path,
				breakpoints: {
					base: { width: 720, height: 650 },
					lg: { width: 1024, height: 451 },
					xl: { width: 1920, height: 515 }
				}
			}
		},
		page () {
			return this.$app.page
		},
		product () {
			return this.page.product
		}
	}
}
</script>
<style lang="scss" scoped>
.product-wrapper {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	@include media-breakpoint-up(lg) {
		flex-direction: row;
	}

	.product-buttons {
		display: flex;
		flex-direction: row;
		gap: 1rem;
		overflow: hidden;

		@include media-breakpoint-up(lg) {
			overflow: visible;
		}
	}
	.product-image-wrapper {
		display: flex;
		justify-content: center;
		position: relative;
		margin: 0;
		flex: 1 1 auto;
		::v-deep .product-brand {
			position: absolute;
			bottom: .5rem;
			left: 0;
			@include media-breakpoint-up(lg) {
				bottom: 2rem;
			}
		}
	}

	.product-details {
		@include media-breakpoint-up(lg) {
			margin-top: -2rem;
			width: 50%;
			flex: 1 0 auto;
		}
	}
	.text-content {
		padding-block: 3rem;
		border-top: 1px solid rgba($gray-500, .2);
		border-bottom: 1px solid rgba($gray-500, .2);
		margin-block: 3rem 6rem;
	}
}
.inner-section {
	position: relative;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	padding-top: 2rem;
	@include media-breakpoint-up(lg) {
		padding-top: 0;
	}
}
</style>
